import {
  ceil,
  filter,
  includes,
  isNil,
  isString,
  map,
  orderBy,
  set,
  isEmpty,
  round,
} from 'lodash';
import { format, parse } from 'date-fns';
import isUuid from '@/utils/is-uuid';
import { findSportById, findCompetitionById } from '@/services/api';
import {
  FilterType,
  FilterCondition,
  defineFilter,
  deserializeFilterQuery,
  bulkMapFiltersToGraphQl,
} from '@/services/helpers/filters';
import { getSuperAdminData } from '@/services/helpers/super-admin';

export const ListSidebar = {
  COLUMNS: 'COLUMNS',
  FILTERS: 'FILTERS',
};

export const DetailsSidebar = {
  FILTERS: 'FILTERS',
  NOTES: 'NOTES',
};

const precisionNumberOffset = 0.0005; // backend numbers have too many decimals for precision and we need offset to cover those cases
const integerPrecisionNumberOffset = 0.05;

const parseNumericValue = (value, defaultValue = null) => (isNil(value) ? defaultValue : value);

export const flattenCustomerObject = (gqlCustomer) => {
  if (!gqlCustomer) return null;
  const {
    kpi,
    bets,
    latestNote,
    ...customer
  } = gqlCustomer;
  return {
    ...customer,
    latestNote: latestNote?.nodes?.[0] || null,
    volume: parseNumericValue(kpi?.volume),
    averageBetSize: parseNumericValue(kpi?.averageBetSize),
    holdPercentage: parseNumericValue(kpi?.holdPercentage),
    edgePercentage: parseNumericValue(kpi?.edgePercentage),
    numberOfBets: parseNumericValue(kpi?.betsCount),
    singlesPercentage: parseNumericValue(kpi?.singlesPercentage),
    pnl: parseNumericValue(kpi?.pnl),
  };
};

export const getDefaultOperator = () => ({
  id: '',
  label: 'All clients',
});

export const getAvailableOperators = (allOperators) => {
  const { isSuperAdmin, client, SUPER_ADMIN_CLIENT } = getSuperAdminData();
  if (!isSuperAdmin || client !== SUPER_ADMIN_CLIENT) return [];
  const availableOperators = filter(allOperators, ({ operatorId }) => operatorId !== SUPER_ADMIN_CLIENT);
  const mappedAvailableOperators = map(availableOperators, ({ operatorId }) => ({
    id: operatorId,
    label: operatorId,
  }));
  return [
    getDefaultOperator(),
    ...mappedAvailableOperators,
  ];
};

export const getLimitOptions = () => [10, 20, 50, 100];

export const getDefaultLimit = () => 50;

export const parseLimit = (limit) => {
  const numericLimit = (isString(limit) ? parseInt(limit, 10) : limit);
  return (includes(getLimitOptions(), numericLimit) ? numericLimit : getDefaultLimit());
};

export const getDefaultPage = () => 1;

export const parsePage = (page, options) => {
  const limit = options?.limit || getDefaultLimit();
  const numericLimit = parseLimit(limit);
  const totalCount = options?.totalCount || 0;
  const numericPage = (isString(page) ? parseInt(page, 10) : page);
  const lastPage = ceil(totalCount / numericLimit);
  return (numericPage >= getDefaultPage() && numericPage <= lastPage ? numericPage : getDefaultPage());
};

export const parsePagination = ({ page, limit, totalCount }) => ({
  limit: parseLimit(limit),
  page: parsePage(page, {
    limit,
    totalCount,
  }),
});

export const CustomerListSidebar = {
  COLUMNS: 'COLUMNS',
  FILTERS: 'FILTERS',
};

export const getCustomerProfilingListColumns = () => [
  {
    key: 'flagCell',
    label: 'Flag',
    field: 'isFlagged',
    visible: true,
    toggleable: false,
    maxWidth: 70,
  },
  {
    key: 'customerId',
    label: 'Customer ID',
    field: 'id',
    visible: true,
    maxWidth: 120,
  },
  {
    key: 'strength',
    label: 'Strength',
    field: 'strength',
    visible: true,
    maxWidth: 75,
  },
  {
    key: 'alias',
    label: 'Alias',
    field: 'nickname',
    visible: true,
  },
  {
    key: 'totalBetsCount',
    label: 'No. of bets',
    field: 'numberOfBets',
    visible: true,
  },
  {
    key: 'volume',
    label: 'Single bets volume',
    field: 'volume',
    visible: true,
  },
  {
    key: 'betSize',
    label: 'Single bets avg. stake',
    field: 'averageBetSize',
    visible: true,
  },
  {
    key: 'hold',
    label: 'Hold',
    field: 'holdPercentage',
    visible: true,
    maxWidth: 100,
  },
  {
    key: 'edge',
    label: 'Single bets edge',
    field: 'edgePercentage',
    visible: true,
  },
  {
    key: 'client',
    label: 'Client',
    field: 'operatorId',
    visible: true,
  },
  {
    key: 'firstBetPlaced',
    label: 'First bet placed',
    field: 'firstPlacedBet',
    visible: true,
  },
  {
    key: 'lastNote',
    label: 'Last note',
    field: 'lastNoteAt',
    visible: true,
  },
];

export const CustomerListFilter = {
  OPERATOR: 'OPERATOR',
  FLAGGED: 'FLAGGED',
  SEARCH: 'SEARCH',
  STRENGTH: 'STRENGTH',
  VOLUME: 'VOLUME',
  STAKE: 'STAKE',
  HOLD: 'HOLD',
  EDGE: 'EDGE',
  FIRST_PLACED_BET: 'FIRST_PLACED_BET',
  BET_COUNT: 'BET_COUNT',
  LAST_NOTE_AT: 'LAST_NOTE_AT',
};

export const getCustomerListFilters = () => orderBy([
  defineFilter({
    id: CustomerListFilter.OPERATOR,
    type: FilterType.STRING,
    meta: {
      gqlField: 'operatorId',
      hideSidebar: true,
    },
  }),
  defineFilter({
    id: CustomerListFilter.FLAGGED,
    type: FilterType.BOOLEAN,
    meta: {
      gqlField: 'isFlagged',
      hideSidebar: true,
    },
  }),
  defineFilter({
    id: CustomerListFilter.SEARCH,
    type: FilterType.STRING,
    meta: {
      gqlField: '*',
      hideSidebar: true,
    },
  }),
  defineFilter({
    id: CustomerListFilter.STRENGTH,
    type: FilterType.INTEGER,
    label: 'Strength',
    meta: {
      gqlField: 'strength',
    },
    validator(value) {
      if (value >= 1 && value <= 5) return '';
      return 'Strength must be an integer from 1 to 5.';
    },
  }),
  defineFilter({
    id: CustomerListFilter.VOLUME,
    type: FilterType.DECIMAL,
    label: 'Volume',
    meta: {
      gqlField: '*',
    },
  }),
  defineFilter({
    id: CustomerListFilter.STAKE,
    type: FilterType.DECIMAL,
    label: 'Avg. bet size',
    meta: {
      gqlField: 'accountKpiByPlayerAccountIdAndOperatorId.averageStake',
    },
  }),
  defineFilter({
    id: CustomerListFilter.HOLD,
    type: FilterType.DECIMAL,
    label: 'Hold',
    meta: {
      gqlField: '*',
    },
  }),
  defineFilter({
    id: CustomerListFilter.EDGE,
    type: FilterType.DECIMAL,
    label: 'Edge',
    meta: {
      gqlField: '*',
    },
  }),
  defineFilter({
    id: CustomerListFilter.FIRST_PLACED_BET,
    type: FilterType.DATE,
    label: 'First bet placed',
    meta: {
      gqlField: 'firstPlacedBet',
    },
    validator(value) {
      if (!value) return 'Invalid date';

      const day = parseInt(value.slice(0, 2), 10);
      const month = parseInt(value.slice(3, 5), 10);
      const year = parseInt(value.slice(6, 10), 10);
      if (
        (day < 1 || day > 31)
        || (month < 1 || month > 12)
        || `${year}`.length !== 4
      ) return 'Date is not realistic';

      try {
        const formattedDate = format(parse(value, 'dd/LL/yyyy', new Date()), 'dd/LL/yyyy');
        if (value !== formattedDate) return 'Invalid date';
      } catch {
        return 'Invalid date';
      }

      return '';
    },
  }),
  defineFilter({
    id: CustomerListFilter.BET_COUNT,
    type: FilterType.INTEGER,
    label: 'No. of bets',
    meta: {
      gqlField: 'accountKpiByPlayerAccountIdAndOperatorId.betsCount',
    },
  }),
  defineFilter({
    id: CustomerListFilter.LAST_NOTE_AT,
    type: FilterType.DATE,
    label: 'Last note',
    meta: {
      gqlField: 'lastNoteAt',
    },
    validator(value) {
      if (!value) return 'Invalid date';

      const day = parseInt(value.slice(0, 2), 10);
      const month = parseInt(value.slice(3, 5), 10);
      const year = parseInt(value.slice(6, 10), 10);
      if (
        (day < 1 || day > 31)
        || (month < 1 || month > 12)
        || `${year}`.length !== 4
      ) return 'Date is not realistic';

      try {
        const formattedDate = format(parse(value, 'dd/LL/yyyy', new Date()), 'dd/LL/yyyy');
        if (value !== formattedDate) return 'Invalid date';
      } catch {
        return 'Invalid date';
      }

      return '';
    },
  }),
], 'label');

export const deserializeCustomerListFilterQuery = (allFilters, options) => deserializeFilterQuery(getCustomerListFilters(), allFilters, options);

export const mapCustomerListWildcardFilterToGraphQl = (filterObject) => {
  switch (filterObject.id) {
  case CustomerListFilter.SEARCH:
    return {
      condition: {
        nicknameSimilarOrPlayerIdEqual: filterObject.value,
      },
    };
  case CustomerListFilter.EDGE:
    switch (filterObject.condition?.id) {
    case FilterCondition.EQUAL:
      return {
        filter: {
          accountKpiByPlayerAccountIdAndOperatorId: {
            edgePercentage: {
              lessThan: filterObject.value / 100 + precisionNumberOffset,
              greaterThan: filterObject.value / 100 - precisionNumberOffset,
            },
          },
        },
      };
    case FilterCondition.LESS_THAN:
      return {
        filter: {
          accountKpiByPlayerAccountIdAndOperatorId: {
            edgePercentage: {
              lessThan: filterObject.value / 100 - precisionNumberOffset,
            },
          },
        },
      };
    case FilterCondition.LESS_THAN_OR_EQUAL:
      return {
        filter: {
          accountKpiByPlayerAccountIdAndOperatorId: {
            edgePercentage: {
              lessThan: filterObject.value / 100 + precisionNumberOffset,
            },
          },
        },
      };
    case FilterCondition.GREATER_THAN:
      return {
        filter: {
          accountKpiByPlayerAccountIdAndOperatorId: {
            edgePercentage: {
              greaterThan: filterObject.value / 100 + precisionNumberOffset,
            },
          },
        },
      };
    case FilterCondition.GREATER_THAN_OR_EQUAL:
      return {
        filter: {
          accountKpiByPlayerAccountIdAndOperatorId: {
            edgePercentage: {
              greaterThan: filterObject.value / 100 - precisionNumberOffset,
            },
          },
        },
      };
    default:
      return {};
    }
  case CustomerListFilter.HOLD:
    switch (filterObject.condition?.id) {
    case FilterCondition.EQUAL:
      return {
        filter: {
          accountKpiByPlayerAccountIdAndOperatorId: {
            holdPercentage: {
              lessThan: filterObject.value / 100 + precisionNumberOffset,
              greaterThan: filterObject.value / 100 - precisionNumberOffset,
            },
          },
        },
      };
    case FilterCondition.LESS_THAN:
      return {
        filter: {
          accountKpiByPlayerAccountIdAndOperatorId: {
            holdPercentage: {
              lessThan: filterObject.value / 100 - precisionNumberOffset,
            },
          },
        },
      };
    case FilterCondition.LESS_THAN_OR_EQUAL:
      return {
        filter: {
          accountKpiByPlayerAccountIdAndOperatorId: {
            holdPercentage: {
              lessThan: filterObject.value / 100 + precisionNumberOffset,
            },
          },
        },
      };
    case FilterCondition.GREATER_THAN:
      return {
        filter: {
          accountKpiByPlayerAccountIdAndOperatorId: {
            holdPercentage: {
              greaterThan: filterObject.value / 100 + precisionNumberOffset,
            },
          },
        },
      };
    case FilterCondition.GREATER_THAN_OR_EQUAL:
      return {
        filter: {
          accountKpiByPlayerAccountIdAndOperatorId: {
            holdPercentage: {
              greaterThan: filterObject.value / 100 - precisionNumberOffset,
            },
          },
        },
      };
    default:
      return {};
    }
  case CustomerListFilter.VOLUME:
    switch (filterObject.condition?.id) {
    case FilterCondition.EQUAL:
      return {
        filter: {
          accountKpiByPlayerAccountIdAndOperatorId: {
            volume: {
              lessThan: (filterObject.value + integerPrecisionNumberOffset) * 100,
              greaterThan: (filterObject.value - integerPrecisionNumberOffset) * 100,
            },
          },
        },
      };
    case FilterCondition.LESS_THAN:
      return {
        filter: {
          accountKpiByPlayerAccountIdAndOperatorId: {
            volume: {
              lessThan: (filterObject.value - integerPrecisionNumberOffset) * 100,
            },
          },
        },
      };
    case FilterCondition.LESS_THAN_OR_EQUAL:
      return {
        filter: {
          accountKpiByPlayerAccountIdAndOperatorId: {
            volume: {
              lessThan: (filterObject.value + integerPrecisionNumberOffset) * 100,
            },
          },
        },
      };
    case FilterCondition.GREATER_THAN:
      return {
        filter: {
          accountKpiByPlayerAccountIdAndOperatorId: {
            volume: {
              greaterThan: (filterObject.value + integerPrecisionNumberOffset) * 100,
            },
          },
        },
      };
    case FilterCondition.GREATER_THAN_OR_EQUAL:
      return {
        filter: {
          accountKpiByPlayerAccountIdAndOperatorId: {
            volume: {
              greaterThan: (filterObject.value - integerPrecisionNumberOffset) * 100,
            },
          },
        },
      };
    default:
      return {};
    }
  default:
    return {};
  }
};

export const bulkMapCustomerListFiltersToGraphQl = (allFilterObjects) => bulkMapFiltersToGraphQl(allFilterObjects, {
  wildcardMapper: mapCustomerListWildcardFilterToGraphQl,
});

export const CustomerDetailsSidebar = {
  FILTERS: 'FILTERS',
  NOTES: 'NOTES',
};

export const getCustomerProfilingDetailsColumns = () => [
  {
    key: 'toggle',
    label: '',
    field: '',
    visible: true,
    nonResizable: true,
    minWidth: 50,
    maxWidth: 50,
  },
  {
    key: 'flag',
    label: '',
    field: 'isFlagged',
    visible: true,
    nonResizable: true,
    minWidth: 50,
    maxWidth: 50,
  },
  {
    key: 'betId',
    label: 'Bet ID',
    field: 'betId',
    visible: true,
    minWidth: 80,
    maxWidth: 80,
  },
  {
    key: 'competition',
    label: 'Competition',
    field: 'competitionName',
    visible: true,
  },
  {
    key: 'event',
    label: 'Event',
    field: 'eventName',
    visible: true,
  },
  {
    key: 'market',
    label: 'Market',
    field: '',
    visible: true,
  },
  {
    key: 'selection',
    label: 'Selection',
    field: '',
    visible: true,
  },
  {
    key: 'odds',
    label: 'Odds',
    field: '',
    visible: true,
    minWidth: 70,
    maxWidth: 70,
  },
  {
    key: 'stake',
    label: 'Stake',
    field: 'stake',
    visible: true,
    minWidth: 70,
    maxWidth: 70,
  },
  {
    key: 'date',
    label: 'Date',
    field: 'date',
    visible: true,
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'time',
    label: 'Time',
    field: 'time',
    visible: true,
    minWidth: 90,
    maxWidth: 90,
  },
  {
    key: 'eventId',
    label: 'Event ID',
    field: 'eventId',
    visible: true,
    minWidth: 80,
    maxWidth: 80,
  },
  {
    key: 'potentialPayout',
    label: 'Potential payout',
    field: 'potentialPayout',
    visible: true,
    minWidth: 125,
    maxWidth: 125,
  },
  /* {
    key: 'limit',
    label: 'Bet limit',
    field: 'limit',
    visible: true,
    minWidth: 90,
    maxWidth: 90,
  }, */
];

export const CustomerDetailsFilter = {
  ACTIVE: 'ACTIVE',
  FLAGGED: 'FLAGGED',
  EVENT_ID: 'EVENT_ID',
  SPORT: 'SPORT',
  POTENTIAL_PAYOUT: 'POTENTIAL_PAYOUT',
  STAKE: 'STAKE',
  ODDS: 'ODDS',
};

export const getCustomerDetailsFilters = () => orderBy([
  defineFilter({
    id: CustomerDetailsFilter.ACTIVE,
    type: FilterType.BOOLEAN,
    meta: {
      gqlField: 'isActive',
      hideSidebar: true,
    },
  }),
  defineFilter({
    id: CustomerDetailsFilter.FLAGGED,
    type: FilterType.BOOLEAN,
    meta: {
      gqlField: 'isFlagged',
      hideSidebar: true,
    },
  }),
  defineFilter({
    id: CustomerDetailsFilter.EVENT_ID,
    type: FilterType.STRING,
    label: 'Event ID',
    meta: {
      gqlField: '*',
    },
    validator(value) {
      if (isUuid(value)) return '';
      return 'Event ID must be a valid UUID.';
    },
  }),
  defineFilter({
    id: CustomerDetailsFilter.SPORT,
    type: FilterType.SPORT,
    label: 'Sport & competitions',
    meta: {
      gqlField: '*',
      hideLabel: true,
      hideError: true,
    },
    async transform(value) {
      let sport = null;
      let competitions = null;
      if (value?.sport?.sportId) {
        sport = await findSportById(value.sport.sportId);
      }
      if (value?.competitions?.length) {
        const competitionPromises = map(value.competitions, (competition) => findCompetitionById(competition.competitionId));
        competitions = await Promise.all(competitionPromises);
      }
      return { sport, competitions };
    },
  }),
  defineFilter({
    id: CustomerDetailsFilter.POTENTIAL_PAYOUT,
    type: FilterType.DECIMAL,
    label: 'Potential payout',
    meta: {
      gqlField: '*',
    },
  }),
  defineFilter({
    id: CustomerDetailsFilter.STAKE,
    type: FilterType.DECIMAL,
    label: 'Stake',
    meta: {
      gqlField: '*',
    },
  }),
  defineFilter({
    id: CustomerDetailsFilter.ODDS,
    type: FilterType.ODDS,
    label: 'Odds',
    meta: {
      gqlField: '*',
    },
  }),
], 'label');

export const deserializeCustomerDetailsFilterQuery = (allFilters, options) => deserializeFilterQuery(getCustomerDetailsFilters(), allFilters, options);

export const mapCustomerDetailsWildcardFilterToGraphQl = (filterObject, options) => {
  switch (filterObject.id) {
  case CustomerDetailsFilter.EVENT_ID:
    return {
      filter: {
        betPartsByBetId: {
          some: {
            eventId: {
              equalTo: filterObject.value,
            },
          },
        },
      },
    };
  case CustomerDetailsFilter.SPORT:
    const sportFilter = {};
    if (filterObject.value?.sport) {
      set(sportFilter, 'filter.betPartsByBetId.some.event.sportId.equalTo', filterObject.value.sport.sportId);
    }
    if (filterObject.value?.competitions) {
      const competitionIds = map(filterObject.value.competitions, ({ competitionId }) => competitionId);
      set(sportFilter, 'filter.betPartsByBetId.some.event.competitionId.in', competitionIds);
    }
    return sportFilter;
  case CustomerDetailsFilter.POTENTIAL_PAYOUT:
    switch (filterObject.condition?.id) {
    case FilterCondition.EQUAL:
      return {
        condition: {
          betPayoutEqualTo: round(filterObject.value * 100),
        },
      };
    case FilterCondition.LESS_THAN:
      return {
        condition: {
          betPayoutLessThan: round(filterObject.value * 100),
        },
      };
    case FilterCondition.LESS_THAN_OR_EQUAL:
      return {
        condition: {
          betPayoutLessThanOrEqualTo: round(filterObject.value * 100),
        },
      };
    case FilterCondition.GREATER_THAN:
      return {
        condition: {
          betPayoutGreaterThan: round(filterObject.value * 100),
        },
      };
    case FilterCondition.GREATER_THAN_OR_EQUAL:
      return {
        condition: {
          betPayoutGreaterThanOrEqualTo: round(filterObject.value * 100),
        },
      };
    default:
      return {};
    }
  case CustomerDetailsFilter.STAKE:
    switch (filterObject.condition?.id) {
    case FilterCondition.EQUAL:
      return {
        condition: {
          betStakeEqualTo: round(filterObject.value * 100),
        },
      };
    case FilterCondition.LESS_THAN:
      return {
        condition: {
          betStakeLessThan: round(filterObject.value * 100),
        },
      };
    case FilterCondition.LESS_THAN_OR_EQUAL:
      return {
        condition: {
          betStakeLessThanOrEqualTo: round(filterObject.value * 100),
        },
      };
    case FilterCondition.GREATER_THAN:
      return {
        condition: {
          betStakeGreaterThan: round(filterObject.value * 100),
        },
      };
    case FilterCondition.GREATER_THAN_OR_EQUAL:
      return {
        condition: {
          betStakeGreaterThanOrEqualTo: round(filterObject.value * 100),
        },
      };
    default:
      return {};
    }
  case CustomerDetailsFilter.ODDS:
    const oddsFormat = options?.oddsFormat || '';
    let formattedParameter = {};
    if (oddsFormat === 'AMERICAN') {
      formattedParameter = {
        originalFormattedPrice: {
          signIsPlus: filterObject.value >= 0,
          value: Math.abs(filterObject.value),
        },
        type: 'ORIGINAL_FORMATTED_PRICE',
      };
    }
    if (oddsFormat === 'DECIMAL') {
      formattedParameter = {
        decimalPrice: Number(filterObject.value),
        type: 'DECIMAL_PRICE',
      };
    }
    if (oddsFormat === 'PROBABILITY') {
      formattedParameter = {
        probability: parseFloat(filterObject.value / 100),
        type: 'PROBABILITY',
      };
    }
    if (isEmpty(formattedParameter)) return {};

    switch (filterObject.condition?.id) {
    case FilterCondition.EQUAL:
      return {
        condition: {
          oddsEqualTo: {
            parameter: formattedParameter,
          },
        },
      };
    case FilterCondition.LESS_THAN:
      return {
        condition: {
          oddsLessThan: {
            parameter: formattedParameter,
          },
        },
      };
    case FilterCondition.LESS_THAN_OR_EQUAL:
      return {
        condition: {
          oddsLessThanOrEqualTo: {
            parameter: formattedParameter,
          },
        },
      };
    case FilterCondition.GREATER_THAN:
      return {
        condition: {
          oddsGreaterThan: {
            parameter: formattedParameter,
          },
        },
      };
    case FilterCondition.GREATER_THAN_OR_EQUAL:
      return {
        condition: {
          oddsGreaterThanOrEqualTo: {
            parameter: formattedParameter,
          },
        },
      };
    default:
      return {};
    }
  default:
    return {};
  }
};

export const bulkMapCustomerDetailsFiltersToGraphQl = (allFilterObjects, context) => bulkMapFiltersToGraphQl(allFilterObjects, {
  wildcardMapper: (filterObject, options) => mapCustomerDetailsWildcardFilterToGraphQl(filterObject, { ...options, ...context }),
});

export const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64)
      .split('')
      // eslint-disable-next-line prefer-template
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
    return JSON.parse(jsonPayload);
  } catch {
    return null;
  }
};

export const parseUserPrincipal = (token) => {
  const { isSuperAdmin, client } = getSuperAdminData();
  const tokenData = parseJwt(token);
  if (!tokenData) return '';
  const operatorId = (isSuperAdmin ? client : tokenData?.['custom:operator_id'] || '');
  const id = tokenData?.sub || '';
  const username = tokenData?.['cognito:username'] || '';
  return `user/${operatorId}/${id}/${username}`;
};
